import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout.ja';
import SEO from '../components/Seo';
import Gallery from '../components/Gallery';
import VideoPlayer from '../components/VideoPlayer';
import Image from '../components/Image';

import launchTrailer from '../images/DeMamboSwitch-FinalTrailer-Japanese-web2.mp4';

export const query = graphql`
  query {
    poster: file(relativePath: { eq: "Screen-Japanese-v3.png" }) {
      childImageSharp {
        fixed(width: 890, height: 501) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    iconSwitch: file(relativePath: { eq: "IconSwitch.png" }) {
      childImageSharp {
        fixed(width: 150, height: 130) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    iconSteam: file(relativePath: { eq: "IconSteam.png" }) {
      childImageSharp {
        fixed(width: 150, height: 130) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    iconPlaystation: file(relativePath: { eq: "IconPlaystation.png" }) {
      childImageSharp {
        fixed(width: 150, height: 130) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    threeAttacks: file(relativePath: { eq: "3attacks.png" }) {
      childImageSharp {
        fluid(maxWidth: 598) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
    chorusLogo: file(relativePath: { eq: "ChorusLogo.png" }) {
      childImageSharp {
        fixed(width: 150, height: 130) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    tdkLogo: file(relativePath: { eq: "TDKLogo.png" }) {
      childImageSharp {
        fixed(width: 150, height: 130) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    outNow: file(relativePath: { eq: "outnow.png" }) {
      childImageSharp {
        fluid(maxWidth: 337) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    text9: file(relativePath: { eq: "text9.png" }) {
      childImageSharp {
        fluid(maxWidth: 592) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    text2: file(relativePath: { eq: "text2.png" }) {
      childImageSharp {
        fluid(maxWidth: 925) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    text3: file(relativePath: { eq: "text3.png" }) {
      childImageSharp {
        fluid(maxWidth: 759) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    text4: file(relativePath: { eq: "text4.png" }) {
      childImageSharp {
        fluid(maxWidth: 1134) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    text5: file(relativePath: { eq: "text5.png" }) {
      childImageSharp {
        fluid(maxWidth: 569) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    text6: file(relativePath: { eq: "text6.png" }) {
      childImageSharp {
        fluid(maxWidth: 344) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    text7: file(relativePath: { eq: "text7.png" }) {
      childImageSharp {
        fluid(maxWidth: 254) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
  }
`;

function JapanesePage({ data }) {
  return (
    <Layout>
      <SEO title="De Mambo" lang="ja" />
      <main>
        <section className="video-player">
          <VideoPlayer video={launchTrailer} poster={data.poster} />
        </section>

        <section className="content first">
          <p className="tagline">
            De
            Mambo（デ・マンボ）では、プレイヤーが操るのは最大4体の「マンボ」と呼ばれる究極の生命体。そのマンボを、恐怖（むしろスリル？）に満ちたこの世界から救い出すのがあなたの使命です！
          </p>
          <br />
          <br />
          <div className="platform-box image-box">
            <div className="platform-logo">
              <Image node={data.iconSwitch} alt="Switch" />
            </div>
            <div className="platform-logo">
              <Image node={data.iconSteam} alt="Steam" />
            </div>
          </div>
          <h2>
            <Image
              node={data.outNow}
              style={{
                margin: '0 auto',
              }}
              alt="近日公開予定"
            />
          </h2>
          <div className="platform-box image-box">
            <div className="platform-logo">
              <Image node={data.iconPlaystation} alt="Playstation" />
            </div>
          </div>
          <h2>
            <Image
              node={data.text9}
              style={{
                margin: '0 auto',
              }}
              alt="近日公開予定"
            />
          </h2>
        </section>

        <section className="content">
          <h2>
            <Image
              node={data.text2}
              style={{
                margin: '0 auto',
              }}
              alt="近日公開予定"
            />
          </h2>
          <br />
          <p>
            De
            Mamboとは、皆さんがこれまでに見たこともない最高の、もしくは最悪のゲーム…って言うと分かりにくいですね。まあ言うなれば、ボタンを見れば押さずにはいられないし、クールなキャラクターが仮想世界でガンガン敵を倒せば気分爽快、そんな我ら「フツー人」にとってDe
            Mamboとは、つまりいわゆる1つのビデオゲームです。
          </p>
          <p>
            De
            Mamboをプレイするのに必要なのは方向パッドとボタン1つだけ。実はこれ、思考回路が少々混線気味なDe
            Mambo開発陣が、近頃のコントローラーにはボタンがたくさんあるのを失念し、つい昔の感覚のままプログラムを組んじゃったというのがもっぱらの噂で…おっと失礼、口が滑りました。
          </p>
          <div className="image-box">
            <div className="divider">
              <Image
                node={data.threeAttacks}
                style={{
                  margin: '0 auto',
                }}
                alt="Three attacks"
              />
            </div>
          </div>
          <p>
            デ・マンボは1つのボタンと方向キー（D-Pad）だけでプレイするよう設計されています。が、将来的にそのほかのボタンやコントロールオプションが必要になるかもしれません。
          </p>
          <p>
            本作の開発者は現行世代の多機能コントローラを駆使するよりも1ボタンに集約することを好むので。使わないボタンがもったいない、とか考えないようにしてください。
          </p>
        </section>

        <section className="content">
          <h2>
            <Image
              node={data.text3}
              style={{
                margin: '0 auto',
              }}
              alt="スクリーンショット"
            />
          </h2>
          <br />
          <Gallery />
        </section>

        <section className="content">
          <h2>
            <Image
              node={data.text4}
              style={{
                margin: '0 auto',
              }}
              alt="De Mamboって、どんなの？"
            />
          </h2>
          <br />
          <ul>
            <li>
              1ボタンde操作：ボタン1つと方向パッド（キー）だけの、覚えやすくも奥が深い操作感
            </li>
            <li>
              マルチdeマンボ：最大4人の仲間や敵同士で大乱闘スマッシュDe Mambo
            </li>
            <li>
              ルーザー・レイル：なかなか勝てない、そんなあなたに捧げる敗者復活線
            </li>
            <li>
              壊れるステージ：反射力を駆使し、壊れて変化し続けるステージでバトル
            </li>
            <li>
              ソロシングルプレイモード：一匹狼のごとくひたすら塔の高みを目指そう
            </li>
            <li>
              宇宙からの侵略者を撃退せよ！ソロでもマルチでも楽しめるハードコアなゲームモード
            </li>
          </ul>
        </section>

        <section className="content" id="press">
          <h2>
            <Image
              node={data.text5}
              style={{
                margin: '0 auto',
              }}
              alt="メディア評価"
            />
          </h2>
          <br />
          <ul>
            <li>
              “There’s something certifiably wacky and insane about all of this,
              and it has my attention.” <b>– Cliqist</b>
            </li>

            <li>
              “日本製ゲームの種子がイギリスで花開く!!” <b>– ファミ通</b>
            </li>

            <li>
              “A mixture of the bizarre and the smart, the artistic and the
              lunatic.” <b>- Thumbsticks</b>
            </li>

            <li>
              “分かりやすいゲームデザインなので徐々に夢中になってしまう”{' '}
              <b>– 4Gamer</b>
            </li>
          </ul>
        </section>

        <section className="content">
          <h2>
            <Image
              node={data.text6}
              style={{
                margin: '0 auto',
              }}
              alt="ニュース"
            />
          </h2>
          <br />
          <ul>
            <li>
              <a
                href="https://www.famitsu.com/news/201609/18116311.html"
                target="_blank"
                rel="noopener noreferrer">
                ファミ通:［TGS
                2016］良質な海外インディーゲームが一気に押し寄せてきた!!　コーラス・ワールドワイド出展タイトルリポート
              </a>
            </li>

            <li>
              <a
                href="https://www.famitsu.com/news/201509/18088927.html"
                target="_blank"
                rel="noopener noreferrer">
                ファミ通:［TGS
                2015］レトロでシンプルで奥深い。だから対戦が熱くなるインディーズゲーム，「De
                Mambo」を紹介
              </a>
            </li>

            <li>
              <a
                href="https://www.4gamer.net/games/318/G031879/20150920024/"
                target="_blank"
                rel="noopener noreferrer">
                4Gamer:［TGS
                2015］レトロでシンプルで奥深い。だから対戦が熱くなるインディーズゲーム，「De
                Mambo」を紹介
              </a>
            </li>

            <li>
              <a
                href="https://www.gamespark.jp/article/2016/07/09/67193.html"
                target="_blank"
                rel="noopener noreferrer">
                Gamespark: 英国製ACT『De
                Mambo』は女性人気も高し！―ノンバーバルなゲーム性に注目
              </a>
            </li>
          </ul>
        </section>

        <section className="content last">
          <h2>
            <Image
              node={data.text7}
              style={{
                margin: '0 auto',
              }}
              alt="リンク"
            />
          </h2>
          <div className="image-box">
            <div className="platform-logo">
              <a
                href="https://chorusworldwide.com/"
                target="_blank"
                rel="noopener noreferrer">
                <Image node={data.chorusLogo} alt="Chorus Worldwide" />
              </a>
            </div>
            <div className="platform-logo">
              <a
                href="http://thedangerouskitchen.co.uk"
                target="_blank"
                rel="noopener noreferrer">
                <Image node={data.tdkLogo} alt="The Dangerous Kitchen" />
              </a>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
}

export default JapanesePage;
